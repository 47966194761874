.footer {
    padding: 3rem 1rem;
    background-color: #282c34;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  
    .footer-container {
      max-width: 1200px;
      width: 100%;
  
      .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
  
        .copyright {
          font-size: 1rem;
          line-height: 1.5;
  
          span {
            color: #61dafb;
            font-weight: bold;
          }
        }
  
        .follow-me {
          text-align: center;
  
          h4 {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
            color: #61dafb;
          }
  
          .stick {
            width: 60px;
            height: 3px;
            background-color: #61dafb;
            margin: 0 auto 1rem;
          }
  
          .social-icons {
            display: flex;
            gap: 1rem;
  
            .social-icon {
              font-size: 1.8rem;
              color: #61dafb;
              transition: color 0.3s ease;
  
              &:hover {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  
    @media (min-width: 768px) {
      padding: 4rem 2rem;
  
      .footer-content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
  
        .follow-me {
          text-align: left;
  
          h4 {
            font-size: 1.8rem;
          }
  
          .stick {
            width: 80px;
            height: 4px;
          }
  
          .social-icons .social-icon {
            font-size: 2rem;
          }
        }
      }
    }
  
    @media (min-width: 1024px) {
      padding: 5rem 3rem;
  
      .footer-content {
        .follow-me h4 {
          font-size: 2rem;
        }
  
        .follow-me .stick {
          width: 100px;
          height: 5px;
        }
  
        .follow-me .social-icons .social-icon {
          font-size: 2.2rem;
        }
      }
    }
  }
  