.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .title {
    text-align: center;
    margin-bottom: 2rem;

    span {
      font-size: 1.2rem;
      color: blue;
    }

    h1 {
      font-size: 2.5rem;
      margin-top: 0.5rem;
      color: #000000;
    }
  }

  .about_container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;

    .about_left,
    .about_right {
      flex: 1;
      min-width: 300px;
      padding: 1rem;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;

      img {
        width: 100%;
        max-width: 400px;
        border-radius: 10px;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
        margin-bottom: 1.5rem;
      }

      .bio {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .bioKey {
          font-weight: bold;
          color: blue;
        }

        .bioValue {
          color: #000000;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about_container {
    flex-direction: column;
  }

  .about_left,
  .about_right {
    img {
      max-width: 100%;
    }
  }
}
