* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  background: #282c34;
  font-family: 'Arial', sans-serif;
  font-weight: 500;
}

.skills-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;

  .title {
    text-align: center;
    margin-bottom: 2rem;

    span {
      font-size: 1.2rem;
      color: blue;
    }

    h1 {
      font-size: 2.5rem;
      margin-top: 0.5rem;
      color: #333;
    }
  }

  .content {
    width: 100%;
    padding: 0 1rem;

    

    .card {
      background-color: #fff;
      padding: 2.5rem;
      text-align: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateY(-10px);
      }

      .icon {
        font-size: 6rem;
        color: rgb(0, 0, 0);
      }
    }
  }
}

@media (max-width: 768px) {
  .skills-container {
    padding: 1rem;

    .title h1 {
      font-size: 2rem;
    }

    .content {
      padding: 0 1rem;

      .slick-dots {
        bottom: -20px;

        li button:before {
          font-size: 10px;
        }
      }

      .card {
        padding: 1rem;

        .icon {
          font-size: 2.5rem;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .skills-container {
    padding: 0.5rem;

    .title h1 {
      font-size: 1.5rem;
    }

    .content {
      padding: 0 0.5rem;

      .slick-dots {
        bottom: -10px;

        li button:before {
          font-size: 8px;
        }
      }

      .card {
        padding: 0.5rem;

        .icon {
          font-size: 1rem;
        }
      }
    }
  }
}
