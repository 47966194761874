.contact-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    .title {
      text-align: center;
      margin-top: 8rem;
      margin-bottom: 2rem;
  
      span {
        font-size: 1.2rem;
        color: blue;
      }
  
      h1 {
        font-size: 2.5rem;
        margin-top: 0.5rem;
        color: #000000;
      }
    }
  
    .contact-content {
      display: flex;
      gap: 5rem;
  
      .contact-left,
      .contact-right {
        flex: 1;
        min-width: 300px;
        padding: 1.5rem;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
        h3 {
          font-size: 1.5rem;
          margin-bottom: 1rem;
          color: #000000;
        }
  
        .contact-text {
          font-size: 1rem;
          margin-bottom: 1.5rem;
          color: #000000;
        }
  
        .contact-item {
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;
  
          .icon {
            margin-right: 1rem;
            font-size: 1.5rem;
            color: blue;
          }
  
          p {
            margin: 0;
            font-size: 1rem;
            color: #000000;
          }
        }
  
        .social-icons {
          display: flex;
          gap: 2rem;
          justify-content: center;
          margin-top: 1.9rem;
  
          .social-icon {
            font-size: 3rem;
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
  
            &:hover {
              color: #000000;
            }
          }
        }
      }
    }


  }
  

  
  
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
    }
  }
  