* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  background: #282c34;
  font-family: "Arial", sans-serif;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  background-color: #ffffff;
  min-height: 100vh;

  .profile {
      margin: 2rem 0;

      img {
          border-radius: 50%;
          width: 200px;
          height: 200px;
          object-fit: cover;
      }
  }

  .profile_text {
      text-align: center;

      .name {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;

          span {
              color: blue;
          }
      }

      .job {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: #000000;
      }

      .text {
          font-size: 0.9rem;
          margin-bottom: 1.5rem;
          line-height: 1.5;
      }

      .connect-button {
          display: inline-block;
          padding: 0.5rem 1.5rem;
          margin: 2rem 0;
          background-color: blue;
          color: #ffffff;
          border-radius: 20px;
          text-decoration: none;
          font-size: 1rem;
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
              background-color: #000000;
          }

          &:active {
              transform: scale(0.95);
          }
      }

      .specializations {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
      }
      
      .specialization {
        background-color: #ffffff;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        font-size: 1rem;
        color: #000000;
        min-width: 150px;
        text-align: center;
        border: 2px solid blue; /* Adicionando borda */
        transition: all 0.3s ease;
      
        &:hover {
          background-color: #000000;
          color: #ffffff;
        }
      }
      
  }

  .news-section {
      width: 100%;
      margin-top: 3rem;

      h2 {
          text-align: center;
          margin-bottom: 2rem;
          color: #000000;
      }

      .news-card {
          background-color: #ffffff;
          padding: 0.5rem;
          border-radius: 10px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          img {
              max-width: 100%;
              border-radius: 10px;
              margin-bottom: 1rem;
              height: 150px;
              object-fit: cover;
          }

          h3 {
              font-size: 1.2rem;
              margin-bottom: 0.5rem;
              color: #000000;
          }

          p {
              font-size: 1rem;
              margin-bottom: 1rem;
              color: #000000;
              flex-grow: 1;
          }

          a {
              font-size: 1rem;
              color: blue;
              text-decoration: none;
              transition: color 0.3s ease-in-out;

              &:hover {
                  color: #000000;
              }
          }
      }
  }
}

@media (min-width: 768px) {
  .container {
      padding: 6rem 2rem;

      .profile img {
          width: 175px;
          height: 175px;
      }

      .profile_text .name {
          font-size: 1.6rem;
      }

      .profile_text .job {
          font-size: 1.1rem;
      }

      .news-section {
          margin-top: 4rem;
      }
  }
}

@media (min-width: 1024px) {
  .container {
      padding: 8rem 3rem;

      .profile img {
          width: 300px;
          height: 300px;
      }

      .profile_text .name {
          font-size: 1.8rem;
      }

      .profile_text .job {
          font-size: 1.2rem;
      }

      .news-section {
          margin-top: 5rem;
      }
  }
}

@media (min-width: 1440px) { 
  .container { 
    padding: 10rem 4rem; 
    
    .profile img { 
      width: 250px; 
      height: 250px; 
    } 
      
      .profile_text .name { 
        font-size: 2rem; 
      } 
      
      .profile_text .job { 
        font-size: 1.4rem; 
      } 
      
      .news-section { 
        margin-top: 6rem; 
      } 
    } 
  }