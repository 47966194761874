.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  
  &.active .nav-container .logo h3 {
   color: #ffffff;
  }

  &.active .nav-container .social-icons .social-icon {
    color: #ffffff;
  }


  &.active{
    background-color: #282c34de;
  }
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;  /* Garantir que os elementos estejam alinhados horizontalmente */
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .logo h3 {
    color: #282c34;
    font-size: 2rem;
    font-weight: bold;
    
  }

  .social-icons {
    display: flex;
    gap: 1rem;

    .social-icon {
      font-size: 2rem;
      color: #282c34;
      transition: color 0.3s ease;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-container {
    padding: 2rem;

    .logo h3 {
      font-size: 1.4rem;
    }

    .social-icons .social-icon {
      font-size: 1.4rem;
    }
  }
}
